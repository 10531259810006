import React from 'react'
import { ThemeProvider } from '@klickmarketing/react-components'
import { Container } from '@material-ui/core'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import styled from 'styled-components'

import ContentfulImageWrapper from '../ContentfulImage/ContentfulImageWrapper'

import LayoutModule from './LayoutModule'
gsap.registerPlugin(ScrollTrigger)

const ALIGNMENT = {
  left: '0 auto 0 0',
  right: '0 0 0 auto',
  center: '0 auto',
  default: '0 auto 0 0',
}

const THEMES = {
  white: 'onBlack',
  black: 'onWhite',
  default: 'onBlack',
}

const LayoutScrollytelling = (props) => {
  const scrollyContainerRef = React.useRef(null)
  const slides = props.slidesCollection.items

  React.useEffect(() => {
    if (!scrollyContainerRef.current) return
    const slideElements = gsap.utils.toArray(
      scrollyContainerRef.current.querySelectorAll('.slide')
    )
    const slideBgElements = gsap.utils.toArray(
      scrollyContainerRef.current.querySelectorAll('.slide-bg')
    )
    const timelines = []
    slideElements.forEach((slide, index) => {
      if (index === slideElements.length - 1) return
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: slide,
          start: 'bottom bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
      tl.fromTo(slideBgElements[index], { autoAlpha: 1 }, { autoAlpha: 0 })
      timelines.push(tl)
    })

    return () => {
      timelines.forEach((tl) => tl.kill())
    }
  }, [])

  return (
    <StickyContainer
      ref={scrollyContainerRef}
      style={{ '--height': `${getHeight(slides)}vh` }}
    >
      <ScrollyContent>
        <Container>
          {slides.map((slide, index) => (
            <div className="slide" key={index}>
              {slide.contentCollection.items.map(
                (slideContent, contentIndex) => {
                  return (
                    <ThemeProvider
                      key={contentIndex}
                      themeType={
                        THEMES[slideContent.textColor] ?? THEMES['default']
                      }
                    >
                      <SlideContent
                        style={{
                          '--margin':
                            ALIGNMENT[slideContent.align] ??
                            ALIGNMENT['default'],
                        }}
                      >
                        {slideContent.contentCollection.items.map(
                          (moduleData, moduleIndex) => (
                            <LayoutModule
                              $mdOptions={{
                                overrides: {
                                  p: {
                                    props: {
                                      variant: 'blurb1',
                                      style: {
                                        wordBreak: 'break-word',
                                        textShadow:
                                          'rgba(0, 0, 0, 0.8) 0px 1px 3px',
                                      },
                                    },
                                  },
                                },
                              }}
                              key={moduleIndex}
                              {...moduleData}
                            />
                          )
                        )}
                      </SlideContent>
                    </ThemeProvider>
                  )
                }
              )}
            </div>
          ))}
        </Container>
      </ScrollyContent>
      <ScrollyBackground>
        {slides.map((slide, index) => {
          return slide.video ? (
            <VideoBackground
              key={index}
              className="slide-bg"
              style={{ zIndex: slides.length - index }}
              {...slide.video}
            />
          ) : (
            <ImageBackground
              key={index}
              className="slide-bg"
              style={{ zIndex: slides.length - index }}
              {...slide.image}
            />
          )
        })}
      </ScrollyBackground>
    </StickyContainer>
  )
}

const VideoBackground = ({ url, style, ...rest }) => (
  <BackgroundContainer style={style}>
    <StyledVideo
      src={url}
      playsInline
      muted={true}
      autoPlay={true}
      loop={true}
      {...rest}
    />
  </BackgroundContainer>
)
const ImageBackground = ({ focalPoint, image, ...rest }) => {
  const { url, style, ...restImg } = image
  return (
    <BackgroundContainer style={style} {...rest}>
      <StyledImg
        context="HERO"
        src={url}
        focalPoint={focalPoint?.focalPoint}
        {...restImg}
      />
    </BackgroundContainer>
  )
}

const StyledImg = styled(ContentfulImageWrapper)`
  object-fit: cover;
`

const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
`

const StickyContainer = styled.div`
  position: relative;
  height: var(--height, 100vh);
`

const ScrollyContent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`

const ScrollyBackground = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;
`

const SlideContent = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  max-width: 552px;
  margin: var(--margin, '0 auto');
  min-width: 343px;
`

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
`

function getHeight(slides) {
  return slides.reduce((acc, slide) => {
    return acc + slide.contentCollection.items.length * 100
  }, 0)
}

export default LayoutScrollytelling
