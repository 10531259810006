import React from 'react'
import {
  Heading,
  match,
  P,
  TextLink,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Link as I18nLink, useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { formatPublishDate } from '../../common/utils'
import ContentfulImageWrapper from '../ContentfulImage/ContentfulImageWrapper'

const COLLECTION_URI = '/collections'

const ArticleHero = ({ article, collections }) => {
  const { language, t } = useI18next()

  if (!article) {
    return (
      <Box my={10.5}>
        <Skeleton variant="rect" width="100%" height="60vh" />
      </Box>
    )
  }

  const image = article?.heroImage

  const authors = article?.personsCollection?.items
  // const length = '5 minute'
  const isContained = article?.heroType === 'Contained'

  return (
    <ThemeProvider themeType="onPrimary">
      <ContentContainer
        position="relative"
        py={10}
        mt={10.5}
        mb={isContained ? '35vh' : 10.5}
        component="header"
        bgcolor={isContained ? 'background.default' : null}
      >
        {!isContained && (
          <BackdropImageContainer>
            <StyledHeroImage
              context="HERO"
              focalPoint={image?.focalPoint?.focalPoint}
              src={image?.image?.url}
              alt={image?.image?.description}
              width={image?.image?.width}
              height={image?.image?.height}
            />
          </BackdropImageContainer>
        )}
        <Container maxWidth="lg">
          {!!collections?.length && <Collections collections={collections} />}
          <Box textAlign="center" my={8}>
            <StyledHeading
              variant="h2"
              component="h1"
              style={{ '--mb': article.subtitle ? '0.3em' : '0.6em' }}
            >
              {article.title}
            </StyledHeading>
            {article.subtitle && (
              <Heading variant="h4" component="h2">
                {article.subtitle}
              </Heading>
            )}
          </Box>
          {authors?.length ? (
            <Box textAlign="center">
              <Authors authors={authors} />
            </Box>
          ) : null}
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box textAlign="right">
              <BoldP component="span" variant="blurb2">
                {formatPublishDate(article.publishDate, language)}
              </BoldP>
            </Box>
            {/* TODO: add this back in when we calculate the article length */}
            {/* <Box textAlign="center" width="64px">
              <BoldP component="span" variant="blurb2">
                |
              </BoldP>
            </Box>
            <Box textAlign="left">
              <BoldP component="span" variant="blurb2">
                {length} read
              </BoldP>
            </Box> */}
          </Box>
          {isContained && (
            <Box height="70vh" mb="-35vh" mt={4} position="relative">
              <StyledHeroImage
                context="HERO"
                focalPoint={image?.focalPoint?.focalPoint}
                src={image?.image?.url}
                alt={image?.image?.description}
                width={image?.image?.width}
                height={image?.image?.height}
              />
            </Box>
          )}
        </Container>
      </ContentContainer>
    </ThemeProvider>
  )
}

const AuthorsP = styled(P)`
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 15px;
`

const Authors = ({ authors }) => {
  const { t } = useI18next()
  const hasCreds = authors.some((author) => author.credentials?.length)
  const divider = hasCreds ? '; ' : ', '
  return (
    <AuthorsP variant="blurb2">
      {t('Authored by', { ns: 'articles' })}{' '}
      {authors.map((author, index) => {
        return (
          <React.Fragment key={index}>
            <Link to={`/discover?people=${author.name}`} component={I18nLink}>
              {author.credentials?.length
                ? `${author.name}, ${author.credentials.join(', ')}`
                : author.name}
            </Link>
            {authors.length - 1 !== index && divider}
          </React.Fragment>
        )
      })}
    </AuthorsP>
  )
}

const Collections = ({ collections }) => {
  const { t } = useI18next()
  const getCollectionString = (collections) =>
    collections?.length > 1 ? (
      collections.map((collection, index) => (
        <React.Fragment key={collection.slug}>
          <SeriesLink
            to={`${COLLECTION_URI}/${collection.slug}`}
            component={I18nLink}
          >
            {collection.title}
          </SeriesLink>
          {collections.length - 1 !== index ? (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span>
          ) : (
            ''
          )}
        </React.Fragment>
      ))
    ) : (
      <SeriesLink
        to={`${COLLECTION_URI}/${collections[0].slug}`}
        component={I18nLink}
      >{`${collections[0].title} ${t('Collection', {
        ns: 'articles',
      })}`}</SeriesLink>
    )

  return (
    <Box textAlign="center">
      <P variant="blurb2">{getCollectionString(collections)}</P>
    </Box>
  )
}

const BackdropImageContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
  }
`

const StyledHeading = styled(Heading)`
  margin-bottom: var(--mb, '0.6em');
`

const Link = styled(TextLink)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const SeriesLink = styled(TextLink)`
  text-decoration-color: #fff;
`

const BoldP = styled(P)`
  font-weight: 900;
`

const StyledHeroImage = styled(ContentfulImageWrapper)`
  object-fit: cover;
  /* width: 100%; */
  /* height: 100%; */
`

const ContentContainer = styled(Box)`
  margin-top: 58px;

  ${match.isSM} {
    margin-top: 64px;
  }

  ${match.isMD} {
    margin-top: 84px;
  }
`

export default ArticleHero
