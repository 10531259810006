import React from 'react'
import { Heading } from '@klickmarketing/react-components'
import { Backdrop, Box, Fade, Modal } from '@material-ui/core'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import styled from 'styled-components'

import ContentfulImageWrapper from '../ContentfulImage/ContentfulImageWrapper'

import LayoutCopy from './LayoutCopy'

const LayoutFocalImage = ({
  title,
  subtitle,
  showTitle = false,
  caption,
  image: { url, description, width, height },
  focalPoint,
  indent,
  zoom,
  containerHeight,
  context,
  ratio: ratioProp,
  textAlign,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false)
  const ratio = ratioProp || height / width
  const ratioPercent = ratio * 100

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box
      width="100%"
      height="100%"
      {...(indent && { maxWidth: `400px`, mx: 'auto' })}
    >
      {showTitle && (
        <Box mb={2} align={textAlign}>
          <StyledHeading variant="h5" component="div">
            {title}
          </StyledHeading>
          {subtitle ? <LayoutCopy content={subtitle} /> : null}
        </Box>
      )}
      <StyledContainer
        height={containerHeight}
        position="relative"
        onClick={zoom && handleOpen}
        {...((!containerHeight && !focalPoint?.focalPoint) ||
        (!containerHeight && ratioProp && focalPoint?.focalPoint)
          ? { pt: `${ratioPercent}%` }
          : { pt: 0 })}
        {...rest}
        style={{ cursor: zoom ? 'pointer' : 'default', ...rest.style }}
      >
        {zoom && (
          <StyledOverlay>
            <ZoomInIcon />
          </StyledOverlay>
        )}
        <ContentfulImageWrapper
          src={url}
          context={context}
          alt={description}
          focalPoint={focalPoint?.focalPoint}
          width={width}
          height={height}
        />
      </StyledContainer>
      {caption && (
        <Box mt={2} align={textAlign}>
          <LayoutCopy content={caption} />
        </Box>
      )}
      {zoom && (
        <StyledModal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <ModalContent>
              <CloseButton onClick={handleClose} />
              <StyledModalImage src={url} alt={description} />
            </ModalContent>
          </Fade>
        </StyledModal>
      )}
    </Box>
  )
}

const StyledContainer = styled(Box)``

const StyledOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  color: white;
  transition: opacity 0.3s;
  opacity: 0;

  @media (hover: hover) {
    ${StyledContainer}:hover & {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    opacity: 1;
  }
`

const StyledModal = styled(Modal)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledModalImage = styled.img`
  max-width: 90vw;
  max-height: 90vh;
  display: block;
`

const ModalContent = styled.div`
  position: relative;
  display: inline-block;
`

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`

const CloseButton = styled.button`
  position: absolute;
  margin: 0;
  padding: 0;
  top: 10px;
  right: 10px;
  width: 55px;
  height: 55px;
  border: 0;
  border-radius: 60%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;

  &:after {
    content: '×';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 26px;
  }
`

export default LayoutFocalImage
