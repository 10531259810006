import React from 'react'

import LayoutBlockquote from './LayoutBlockquote'
import LayoutCopy from './LayoutCopy'
import LayoutImage from './LayoutImage'
import LayoutScrollytelling from './LayoutScrollytelling'
import LayoutTwoColumns from './LayoutTwoColumns'
import LayoutForm from './LayoutForm'

const modules = {
  LayoutBlockquote,
  LayoutCopy,
  ImageWithFocalPoint: LayoutImage,
  LayoutTwoColumns,
  LayoutScrollyTelling: LayoutScrollytelling,
  LayoutForm: LayoutForm,
}

const LayoutModule = ({ __typename, ...props }) => {
  const Module = modules[__typename]
  return <Module {...props} />
}

export default LayoutModule
