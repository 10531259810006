import React, { useState } from 'react'
import {
  Button,
  ButtonLoader,
  ConsentText,
  getCookie,
  Heading,
  match,
  RadioGroup,
  TextField,
} from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import { difference as _difference } from 'lodash'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'

import { HUBSPOT_FORM_API } from '../../../config'
import ContentfulImageWrapper from '../ContentfulImage/ContentfulImageWrapper'

import LayoutCopy from './LayoutCopy'

const BOT_SNIFFER = 'phone_kh'

const LayoutForm = ({
  title,
  format,
  introText,
  outroText,
  ctaImage,
  startButtonLabel,
  watermark,
  successMessage,
  failureMessage,
  formData,
}) => {
  const [submitStatus, setSubmitStatus] = useState()
  const [currentStep, setCurrentStep] = useState(0) // For wizard format
  const consentRef = React.useRef()
  const formElId = `form_${formData.id}`
  const introProps = { title, introText, ctaImage, startButtonLabel }

  // Determine if the first step should be the intro step
  const hasIntroStep = !!ctaImage || !!introText
  const startStepOffset = hasIntroStep ? 1 : 0
  const fieldGroups = formData.fieldGroups

  // Sort the email field to the end
  const sortedFieldGroups = fieldGroups.map((group) => ({
    ...group,
    fields: [
      ...group.fields.filter((field) => field.fieldType !== 'email'),
      ...group.fields.filter((field) => field.fieldType === 'email'),
    ],
  }))

  const validationSchema = Yup.object().shape(
    sortedFieldGroups.reduce((acc, group) => {
      group.fields.forEach((field) => {
        acc[field.name] = getFieldValidationSchema(field)
      })
      return acc
    }, {})
  )

  const visualCurrentStep = currentStep - startStepOffset
  const visualStepCount = sortedFieldGroups.length

  const isLastStep =
    currentStep === sortedFieldGroups.length - 1 + startStepOffset
  const isFirstStep = currentStep === 0
  const currentGroup = sortedFieldGroups[currentStep - startStepOffset]

  const handleFormSubmit = async ({
    values,
    formik,
    setSubmitStatus,
    consentRef,
  }) => {
    try {
      formik.setSubmitting(true)

      const finalKeys = _difference(Object.keys(values), [BOT_SNIFFER])

      const fields = [
        ...finalKeys.map((key) => ({
          name: key,
          value: values[key],
        })),
      ]

      const hutk = getCookie('hubspotutk')

      const payload = {
        fields,
        context: {
          pageName: document.title,
          pageUri: window.location.href,
          ...(hutk && { hutk }),
        },
        legalConsentOptions: {
          legitimateInterest: {
            subscriptionTypeIds: [12261438],
            legalBasis: 'LEAD',
            text: consentRef?.current.outerHTML || '',
            value: true,
          },
        },
      }

      const res = await axios
        .post(`${HUBSPOT_FORM_API}/${formData.id}`, payload)
        .catch((e) => {
          console.error(e)
          return e.response
        })

      if (!res || res.status !== 200) {
        throw new Error('Oops! Something went wrong.')
      }

      formik.setSubmitting(false)
      setSubmitStatus('success')
      setCurrentStep(0)
      formik.resetForm()
    } catch (e) {
      formik.setSubmitting(false)
      setSubmitStatus('fail')
      setCurrentStep(0)
      formik.resetForm()
    }
  }

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0))
  }

  return (
    <div id={formElId}>
      {submitStatus === 'success' && (
        <FormSuccess
          setSubmitStatus={setSubmitStatus}
          formElId={formElId}
          content={successMessage}
        />
      )}
      {submitStatus === 'fail' && (
        <FormFailure
          setSubmitStatus={setSubmitStatus}
          formElId={formElId}
          content={failureMessage}
        />
      )}
      {!submitStatus && (
        <Formik
          initialValues={sortedFieldGroups.reduce((acc, group) => {
            group.fields.forEach((field) => {
              acc[field.name] = ''
            })
            acc[BOT_SNIFFER] = BOT_SNIFFER
            return acc
          }, {})}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => {
            handleFormSubmit({ values, formik, setSubmitStatus, consentRef })
          }}
        >
          {({ errors, touched, isSubmitting, values }) => {
            const canProceedToNext = currentGroup
              ? currentGroup.fields.every((field) => {
                  const value = values[field.name]?.trim()
                  const isValidEmail =
                    field.fieldType !== 'email' ||
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
                  return (!field.required || value !== '') && isValidEmail
                })
              : false

            return (
              <Form>
                {format === 'wizard' ? (
                  <>
                    {isFirstStep && hasIntroStep && (
                      <IntroStep handleNext={handleNext} {...introProps} />
                    )}
                    {!isFirstStep || !hasIntroStep ? (
                      <FormSlide $watermark={watermark}>
                        <FormContent>
                          <div>
                            <Pagination
                              currentStep={visualCurrentStep}
                              stepCount={visualStepCount}
                            />
                            {isLastStep && (
                              <MarkdownBlock content={outroText} />
                            )}
                            {currentGroup.fields.map((field) => (
                              <FieldComponent
                                key={field.name}
                                field={field}
                                errors={errors}
                                touched={touched}
                              />
                            ))}
                            {isLastStep && (
                              <>
                                {sortedFieldGroups.some((group) =>
                                  group.fields.some(
                                    (field) => field.fieldType === 'email'
                                  )
                                )}
                                <StyledConsentText ref={consentRef} />
                              </>
                            )}
                          </div>
                        </FormContent>
                        <ActionBar>
                          <div>
                            {!(
                              isFirstStep ||
                              (hasIntroStep && currentStep === 1)
                            ) && (
                              <ActionButton
                                color="secondary"
                                variant="contained"
                                onClick={handleBack}
                                size="large"
                              >
                                Back
                              </ActionButton>
                            )}
                          </div>
                          <div>
                            {isLastStep ? (
                              <ButtonLoader
                                size="large"
                                type="submit"
                                loading={isSubmitting}
                                disableElevation={true}
                                disabled={!canProceedToNext}
                              >
                                Submit
                              </ButtonLoader>
                            ) : (
                              <ActionButton
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleNext}
                                disabled={!canProceedToNext}
                              >
                                Next
                              </ActionButton>
                            )}
                          </div>
                        </ActionBar>
                      </FormSlide>
                    ) : null}
                  </>
                ) : (
                  <FormSlide $waterMark={watermark}>
                    <FormContent>
                      {sortedFieldGroups.map((group, index) =>
                        group.fields.map((field) => {
                          return (
                            <FieldComponent
                              key={field.name}
                              field={field}
                              errors={errors}
                              touched={touched}
                            />
                          )
                        })
                      )}
                      <StyledConsentText ref={consentRef} />
                      <ButtonLoader
                        size="large"
                        type="submit"
                        loading={isSubmitting}
                        disableElevation={true}
                        disabled={isSubmitting || !Object.keys(touched).length}
                      >
                        {formData.displayOptions.submitButtonText}
                      </ButtonLoader>
                    </FormContent>
                  </FormSlide>
                )}
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

const PaginationContainer = styled.div`
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
  color: #929292;
  & > strong {
    color: #0343fb;
  }
`

const Pagination = ({ currentStep, stepCount }) => {
  return (
    <PaginationContainer>
      Question: <strong>{currentStep + 1}</strong>&nbsp;of&nbsp;
      <strong>{stepCount}</strong>
    </PaginationContainer>
  )
}

const IntroStep = ({
  ctaImage,
  title,
  introText,
  startButtonLabel,
  handleNext,
}) => (
  <Intro>
    <FormSlide $isIntro={true}>
      <Heading variant="h2" component="h4">
        {title}
      </Heading>
      <Heading variant="h5">{introText}</Heading>
      <Button variant="contained" size="large" onClick={handleNext}>
        {startButtonLabel}
      </Button>
    </FormSlide>

    <BackgroundImage
      src={ctaImage.image.url}
      alt=""
      focalPoint={ctaImage.image.focalPoint?.focalPoint}
      width={ctaImage.image.width}
      height={ctaImage.image.height}
    />
  </Intro>
)

const getFieldValidationSchema = (field) => {
  switch (field.fieldType) {
    case 'email':
      return Yup.string()
        .email('Please enter a valid email.')
        .required(field.required ? 'This field is required.' : undefined)
    case 'radio':
      return Yup.string().required(
        field.required ? 'This field is required.' : undefined
      )
    default:
      return Yup.string().required(
        field.required ? 'This field is required.' : undefined
      )
  }
}

const FieldComponent = ({ field, errors, touched }) => {
  const error = errors[field.name] && touched[field.name]
  if (field.hidden) {
    return (
      <Box display="none">
        <Field
          name={field.name}
          id={field.name}
          value={field.value}
          component={TextField}
          type="hidden"
        />
      </Box>
    )
  }

  switch (field.fieldType) {
    case 'radio':
      return (
        <RadioGroup
          label={field.label}
          name={field.name}
          id={field.name}
          options={field.options}
          error={!!error}
          helperText={
            typeof errors[field.name] === 'string' ? errors[field.name] : ''
          }
        />
      )
    case 'email':
      return (
        <TextField
          fullWidth
          label={field.label}
          type="email"
          name={field.name}
          id={field.name}
          error={!!error}
          helperText={
            typeof errors[field.name] === 'string' ? errors[field.name] : ''
          }
        />
      )
    default:
      return (
        <TextField
          fullWidth
          label={field.label}
          type="text"
          name={field.name}
          id={field.name}
          error={!!error}
          helperText={
            typeof errors[field.name] === 'string' ? errors[field.name] : ''
          }
        />
      )
  }
}

const scrollFormIntoView = (elementId) => {
  document.getElementById(elementId).scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}

const FormSuccess = ({ setSubmitStatus, formElId, content }) => {
  React.useEffect(() => {
    scrollFormIntoView(formElId)
  }, [formElId])

  return (
    <FadeIn>
      <FormSlide>
        <FormContent>
          <MarkdownBlock content={content} />
          <Button size="large" onClick={() => setSubmitStatus(null)}>
            Reset Form
          </Button>
        </FormContent>
      </FormSlide>
    </FadeIn>
  )
}

const FormFailure = ({ setSubmitStatus, formElId, content }) => {
  React.useEffect(() => {
    scrollFormIntoView(formElId)
  }, [formElId])

  return (
    <FormSlide>
      <MarkdownBlock content={content} />
      <Button size="large" onClick={() => setSubmitStatus(null)}>
        Reset Form
      </Button>
    </FormSlide>
  )
}

const Intro = styled.div`
  position: relative;
`

const BackgroundImage = styled(ContentfulImageWrapper)`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`

const StyledConsentText = styled(ConsentText)`
  margin-top: 1rem;
`

const ActionButton = styled(Button)`
  min-width: 100px;
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const questionDivider = css`
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
`

const FormContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 85%;

  ${match.isSM} {
    max-width: 75%;
  }

  & fieldset:not([aria-hidden='true']) legend {
    font-size: 2em;
    color: #000;
    ${questionDivider}

    ${match.isSM} {
      font-size: 4em;
    }
  }

  & fieldset {
    padding-bottom: 1.5em;
  }

  & fieldset > .MuiFormHelperText-root {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const MarkdownBlock = styled(LayoutCopy)`
  ${questionDivider}

  ${Heading} {
    margin-bottom: 0.05em;
    font-weight: 700;
    font-family: 'PublicaSans', sans-serif;
  }

  h2 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.5em;
    padding-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.5);
  }

  p {
    font-size: 1.5em;
    line-height: 1em;
  }

  ${match.isSM} {
    h4 {
      font-size: 1.5em;
    }

    p {
      font-size: 2em;
    }

    h2 {
      font-size: 3.5em;
    }
  }
`

const FormSlide = styled.div`
  position: relative;
  background: ${({ $isIntro }) => ($isIntro ? 'none' : '#ebebeb')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  padding: 2rem;
  z-index: 2;

  ${({ $isIntro }) =>
    $isIntro
      ? css`
          text-align: center;
          & > ${Heading} {
            color: #fff;
          }
        `
      : ''}

  & ${FormContent}, & ${ActionBar} {
    z-index: 2;
  }

  ${({ $watermark }) =>
    $watermark &&
    css`
      &:after {
        content: '${$watermark}';
        position: absolute;
        top: 0;
        left: 0;
        font-size: 45em;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.04);
        z-index: 1;
        line-height: 1.03em;
        transform: translateX(-30%);
      }
    `}
`

const FadeIn = styled.div`
  animation: fadein 0.7s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export default LayoutForm
