import React from 'react'
import { P } from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import styled from 'styled-components'

import LayoutModule from './LayoutModule'

const ArticleModules = ({ articleModules, context, loading }) => {
  const containerSizes = {
    LayoutCopy: context === 'news' ? 'md' : 'sm',
    LayoutBlockquote: 'sm',
    default: 'md',
  }

  if (typeof articleModules?.length !== 'number' || loading) {
    return (
      <StyledContainer maxWidth={containerSizes['LayoutCopy']}>
        <P>
          <Skeleton variant="text" />
        </P>
        <P>
          <Skeleton variant="text" />
        </P>
        <P>
          <Skeleton variant="text" />
        </P>
        <P>
          <Skeleton variant="text" />
        </P>
        <P>
          <Skeleton variant="text" />
        </P>
        <P>
          <Skeleton variant="text" />
        </P>
      </StyledContainer>
    )
  }

  return articleModules.map(({ sys, ...props }, index) => {
    if (
      props.__typename === 'LayoutTwoColumns' ||
      props.__typename === 'LayoutScrollyTelling'
    ) {
      return (
        <Box mb={6} key={index}>
          <LayoutModule {...props} />
        </Box>
      )
    }
    const isImage = props.__typename === 'ImageWithFocalPoint'
    const hasFocalPoint = !!props?.focalPoint?.focalPoint
    return (
      <Box key={index} mb={6}>
        <Container
          maxWidth={
            containerSizes[props.__typename] ?? containerSizes['default']
          }
        >
          <LayoutModule {...props} {...(isImage && { focalPoint: null })} />
        </Container>
      </Box>
    )
  })
}

const StyledContainer = styled(Container)`
  min-height: 100vh;
`

export default ArticleModules
